import React, { useEffect, useState } from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { useLockBodyScroll } from 'react-use';
import clsx from 'clsx';
import { Logo } from 'components/Logo/Logo';
import { ButtonVariant } from 'components/Button';
import { ButtonContactUs } from 'components/ButtonContactUs/ButtonContactUs';
import { useWindowSize } from 'hooks/useWindowSize';
import { Icon } from 'components/Icon';
import { telLink, telNumber } from 'store/config';
import { ROUTE_AGRICULTURAL, ROUTE_MILITARY, ROUTE_TRAINING } from '../../../routeLinks';
import { ReactComponent as IconBurger } from './_assets/burger.svg';
import { MobileNav } from './MobileNav';
import s from './Header.module.scss';

const links = [
  {
    to: ROUTE_AGRICULTURAL,
    label: 'Для агрокомплекса'
  },
  {
    to: ROUTE_MILITARY,
    label: 'Для оборонной отрасли'
  },
  {
    to: ROUTE_TRAINING,
    label: 'Обучение'
  }
];

const Links = ({ showLinks }: { showLinks: boolean }) => {
  const { pathname } = useLocation();

  return (
    <div className={s.Links}>
      {showLinks &&
        links.map((link, iLink) => (
          <Link
            key={iLink}
            className={clsx(s.Link, {
              [s.Link_active]: !!matchPath(link.to, pathname)
            })}
            to={link.to}
          >
            {link.label}
          </Link>
        ))}

      <a className={s.Link} href={telLink} target="_blank" rel="noopener noreferrer">
        {telNumber}
      </a>
      <ButtonContactUs className={s.TelegramButton} variant={ButtonVariant.Secondary}>
        Связаться с нами
      </ButtonContactUs>
    </div>
  );
};

export interface HeaderProps {
  showLinks?: boolean;
}

export const Header = ({ showLinks = true }: HeaderProps) => {
  const [scrolled, setScrolled] = useState(false);
  const [navOpen, setNavOpen] = useState(false);
  const { isDesktop } = useWindowSize();

  useLockBodyScroll(navOpen);

  useEffect(() => {
    setNavOpen(false);
  }, [isDesktop]);

  useEffect(() => {
    function handleScroll() {
      setScrolled(window.scrollY > 0);
    }

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={clsx(s.Header, {
        [s.Header_scrolled]: scrolled
      })}
    >
      <div className={s.Header__content}>
        <Logo className={s.Header__logo} />

        <div className={s.Header__ext}>
          {isDesktop ? (
            <Links showLinks={showLinks} />
          ) : (
            <>
              <Icon className={s.BurgerButton} icon={IconBurger} onClick={() => setNavOpen(!navOpen)} />
              <MobileNav open={navOpen} onClose={() => setNavOpen(false)} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
