import React from 'react';
import { ButtonContactUs } from 'components/ButtonContactUs/ButtonContactUs';
import { ButtonVariant } from 'components/Button';
import { IconSize } from 'components/Icon';
import { telLink, telNumber } from 'store/config';
import qrCodeSrc from './_assets/qr.png';
import logoSrc from './_assets/logo_white.svg';
import s from './PlaceholderPage.module.scss';

export function PlaceholderPage() {
  return (
    <div className={s.PlaceholderPage}>
      <div className={s.Content}>
        <img className={s.Logo} src={logoSrc} alt="Фабрика дронов" />
        <img className={s.QrCode} src={qrCodeSrc} alt="Фабрика дронов" />

        <a className={s.TelLink} href={telLink} target="_blank" rel="noopener noreferrer">
          {telNumber}
        </a>

        <ButtonContactUs
          className={s.ContactButton}
          variant={ButtonVariant.Secondary}
          iconLeftProps={{ size: IconSize.Medium }}
        >
          Связаться с нами
        </ButtonContactUs>
      </div>
    </div>
  );
}
