import React from 'react';
import { Link } from 'react-scroll';
import { isNil } from 'lodash-es';
import { reactScrollLinkConfig } from './constants';

export interface ScrollLinkProps {
  className?: string;
  activeClass?: string;
  to: string;
  smooth?: boolean;
  duration?: number;
  hashSpy?: boolean;
  spyThrottle?: number;
  offset?: number;
  addOffset?: number;
  onClick?: () => void;
  children?: React.ReactNode;
}

export const ScrollLink = ({ children, offset, addOffset = 0, ...props }: ScrollLinkProps) => {
  const scrollOffset = isNil(offset) ? reactScrollLinkConfig.offset + addOffset : offset;

  return (
    <Link {...reactScrollLinkConfig} offset={scrollOffset} {...props}>
      {children}
    </Link>
  );
};
