import { cssVar } from 'utils/cssVar';

export const reactScrollDefaults = {
  smooth: true,
  offset: 0,
  duration: 500
};

export const reactScrollLinkConfig = {
  ...reactScrollDefaults,
  offset: -parseFloat(cssVar('--header-height', 0))
};
