import { ScrollLinkProps } from 'components/autoScroll';
import { define } from 'utils/object/define';

export enum DronesVariant {
  Agricultural = 'agricultural',
  Military = 'military',
  Training = 'training'
}

type SectionType = Pick<ScrollLinkProps, 'offset' | 'addOffset'> & {
  id: string;
  name: string;
};

export type DronesVariantSectionType = Record<string, SectionType>;

export const dronesVariantSections = define<Record<DronesVariant, DronesVariantSectionType>>()({
  [DronesVariant.Agricultural]: {
    monitoring: {
      id: 'monitoring',
      name: 'Мониторинг территории',
      addOffset: 20
    },
    optimization: {
      id: 'optimization',
      name: 'Оптимизация ресурсов',
      addOffset: 20
    },
    control: {
      id: 'control',
      name: 'Управление стадом',
      addOffset: 20
    },
    advantages: {
      id: 'advantages',
      name: 'Преимущества дронов',
      addOffset: 10
    }
  },
  [DronesVariant.Military]: {
    watching: {
      id: 'watching',
      name: 'Наблюдение',
      addOffset: 20
    },
    security: {
      id: 'security',
      name: 'Защита территории',
      addOffset: 20
    },
    searchAndHelp: {
      id: 'searchAndHelp',
      name: 'Поиск и спасание',
      addOffset: 20
    },
    advantages: {
      id: 'advantages',
      name: 'Преимущества дронов',
      addOffset: 10
    }
  },
  [DronesVariant.Training]: {
    photoAndVideo: {
      id: 'photoAndVideo',
      name: 'Фото и видео',
      addOffset: 20
    },
    competitions: {
      id: 'competitions',
      name: 'Гонки и соревнования',
      addOffset: 20
    },
    stemEducation: {
      id: 'stemEducation',
      name: 'STEM-образование',
      addOffset: 20
    },
    advantages: {
      id: 'advantages',
      name: 'Преимущества дронов',
      addOffset: 10
    }
  }
});

export const dronesPagesDefaultSections = {
  services: {
    id: 'services',
    name: 'Наши услуги'
  },
  products: {
    id: 'products',
    name: 'Сопутствующие товары'
  },
  contacts: {
    id: 'contacts',
    name: 'Контакты'
  }
};
