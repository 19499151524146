import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ROUTE_HOME } from '../../routeLinks';
import logoSrc from './_assets/logo.svg';
import s from './Logo.module.scss';

export interface LogoProps {
  className?: string;
  classes?: {
    root?: string;
    image?: string;
  };
}

export const Logo = ({ className, classes }: LogoProps) => {
  return (
    <Link className={clsx(s.Logo, className, classes?.root)} to={ROUTE_HOME}>
      <img className={clsx(s.Logo__img, classes?.image)} src={logoSrc} alt="" />
    </Link>
  );
};
