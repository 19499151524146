import React, { Fragment } from 'react';
import { Link, To } from 'react-router-dom';
import clsx from 'clsx';
import { dronesPagesDefaultSections } from 'store/types/constants';
import { ScrollLink, ScrollLinkProps } from 'components/autoScroll';
import { Icon, IconDismiss } from 'components/Icon';
import s from './Header.module.scss';

type LinkType = Pick<ScrollLinkProps, 'offset' | 'addOffset'> & {
  label: string;
  to?: To;
  scrollTo?: string;
};

const defaultLinks: LinkType[][] = [
  [
    // {
    //   label: dronesPagesDefaultSections.products.name,
    //   scrollTo: dronesPagesDefaultSections.products.id
    // },
    {
      label: dronesPagesDefaultSections.services.name,
      scrollTo: dronesPagesDefaultSections.services.id
    },
    {
      label: dronesPagesDefaultSections.contacts.name,
      scrollTo: dronesPagesDefaultSections.contacts.id
    }
  ]
];

export interface MobileNavProps {
  open: boolean;
  onClose: () => void;
}

export const MobileNav = ({ open, onClose }: MobileNavProps) => {
  // const { pathname } = useLocation();

  const linksGroups = (() => {
    // const dronesVariant = Object.values(DronesVariant).find((v) => matchPath(v, pathname));
    // const dronesSections = dronesVariant
    //   ? (dronesVariantSections[dronesVariant] as DronesVariantSectionType)
    //   : undefined;
    //
    // const linksList = defaultLinks.slice();
    //
    // if (dronesSections) {
    //   linksList.unshift(
    //     Object.values(dronesSections).map((s) => ({ label: s.name, scrollTo: s.id, addOffset: s.addOffset }))
    //   );
    // }
    //
    // return linksList;
    return defaultLinks;
  })();

  return (
    <>
      <div
        className={clsx(s.MobileNavOffset, {
          [s.MobileNavOffset_open]: open
        })}
        onClick={onClose}
      />

      <div
        className={clsx(s.MobileNav, {
          [s.MobileNav_open]: open
        })}
      >
        <Icon className={s.MobileNav__closeButton} icon={IconDismiss} onClick={onClose} />
        <div className={s.MobileNav__content}>
          <div className={s.MobileNav__links}>
            {linksGroups.map((group, iGroup) => (
              <Fragment key={iGroup}>
                {group.map(({ label, scrollTo, to, offset, addOffset }, iLink) => {
                  if (scrollTo) {
                    return (
                      <ScrollLink
                        key={iLink}
                        className={s.MobileNav__link}
                        to={scrollTo}
                        offset={offset}
                        addOffset={addOffset}
                        onClick={onClose}
                      >
                        {label}
                      </ScrollLink>
                    );
                  }
                  if (to) {
                    return (
                      <Link key={iLink} className={s.MobileNav__link} to={to} onClick={onClose}>
                        {label}
                      </Link>
                    );
                  }
                  return (
                    <div key={iLink} className={s.MobileNav__link} onClick={onClose}>
                      {label}
                    </div>
                  );
                })}
                {iGroup < linksGroups.length - 1 && <div className={s.MobileNav__divider} />}
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};
