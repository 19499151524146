import React, { PropsWithChildren } from 'react';
import { emailLink } from 'store/config';
import { IconEmail } from 'components/Icon';
import { Button, ButtonVariant } from 'components/Button';
import { ButtonProps } from 'components/Button/Button';
import s from './ButtonContactUs.module.scss';

export interface ButtonContactUsProps extends PropsWithChildren, Pick<ButtonProps, 'variant' | 'iconLeftProps'> {
  className?: string;
}

export const ButtonContactUs = (props: ButtonContactUsProps) => {
  return (
    <Button
      Component={'a'}
      href={emailLink}
      classes={{ root: s.ButtonContactUs, icon: s.ButtonContactUs__icon }}
      iconLeft={IconEmail}
      variant={ButtonVariant.Secondary}
      {...props}
    />
  );
};
