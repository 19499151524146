import React from 'react';
import { Logo } from 'components/Logo/Logo';
import { ButtonScrollUp } from 'components/ButtonScrollUp/ButtonScrollUp';
import qrCodeSrc from './_assets/qr-code.png';
import s from './Footer.module.scss';

export const Footer = () => {
  return (
    <div className={s.Footer}>
      <div className={s.Footer__content}>
        <div className={s.Footer__left}>
          <Logo className={s.Footer__logo} />
          <p className={s.Copyright}>© ООО &laquo;Белый пароход&raquo; 2023 г.</p>
        </div>
        <div className={s.Footer__right}>
          <img className={s.QrCode} src={qrCodeSrc} alt="" />
          <ButtonScrollUp />
        </div>
      </div>
    </div>
  );
};
