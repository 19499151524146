import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header, HeaderProps } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import s from './BaseLayout.module.scss';

export interface BaseLayoutProps {
  showHeader?: boolean;
  showFooter?: boolean;
  headerProps?: HeaderProps;
}

export function BaseLayout({ showHeader = true, showFooter = true, headerProps }: BaseLayoutProps) {
  return (
    <main className={s.BaseLayout}>
      {showHeader && <Header {...headerProps} />}
      <div className={s.BaseLayout__content}>
        <Outlet />
      </div>
      {showFooter && <Footer />}
    </main>
  );
}
