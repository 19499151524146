export const API_URL = process.env.REACT_APP_API_URL || '/api';

export const telNumber = '+7 (929) 919-79-65';
export const telLink = `tel:+${telNumber.replace(/\D/g, '')}`;

export const email = 'mail@mwings.ru';
export const emailLink = `mailto:${email}`;

export const telegramPhoneNumber = telNumber;
export const telegramLink = `https://t.me/+${telegramPhoneNumber.replace(/\D/g, '')}`;
