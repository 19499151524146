import React, { MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Icon, IconArrowUp } from 'components/Icon';
import s from './ButtonScrollUp.module.scss';

export interface ButtonScrollUpProps {
  className?: string;
  onClick?: MouseEventHandler;
}

export const ButtonScrollUp = ({ className, onClick }: ButtonScrollUpProps) => {
  const handleClick: MouseEventHandler = (e) => {
    onClick?.(e);
    if (!e.isDefaultPrevented()) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  return (
    <button className={clsx(s.ButtonScrollUp, className)} onClick={handleClick}>
      <Icon className={s.ButtonScrollUp__icon} icon={IconArrowUp} />
    </button>
  );
};
