import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useScrollTopOnRouteChange } from 'utils/useScrollTopOnRouteChange';
import { ROUTE_HOME } from '../routeLinks';
import { BaseLayout } from './_layouts';
import { PlaceholderPage } from './placeholder';

export function AppRoutes() {
  useScrollTopOnRouteChange();

  return (
    <Routes>
      {/*<Route element={<BaseLayout showFooter={false} showHeader={false} />}>*/}
      {/*  <Route path={ROUTE_HOME} element={<HomePage />} />*/}
      {/*</Route>*/}
      {/*<Route element={<BaseLayout />}>*/}
      {/*  <Route path={ROUTE_TRAINING} element={<TrainingPage />} />*/}
      {/*  <Route path={ROUTE_MILITARY} element={<MilitaryPage />} />*/}
      {/*  <Route path={ROUTE_AGRICULTURAL} element={<AgriculturalPage />} />*/}
      {/*</Route>*/}
      {/*<Route path={'*'} element={<Navigate to={ROUTE_HOME} replace />} />*/}

      {/*<Route element={<BaseLayout headerProps={{ showLinks: false }} />}>*/}
      {/*  <Route path={ROUTE_MILITARY} element={<MilitaryPage />} />*/}
      {/*</Route>*/}

      <Route element={<BaseLayout showHeader={false} showFooter={false} />}>
        <Route path={ROUTE_HOME} element={<PlaceholderPage />} />
      </Route>

      {/* not found */}
      <Route path={'*'} element={<Navigate to={ROUTE_HOME} replace />} />
    </Routes>
  );
}
