import React, { ElementType, MouseEventHandler } from 'react';
import clsx from 'clsx';
import { Icon, IconSize } from 'components/Icon';
import { WrapperComponentProps } from 'utils/helperTypes';
import { IconProps } from 'components/Icon/Icon';
import s from './Button.module.scss';

export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Link = 'link'
}

export interface ButtonClasses {
  root?: string;
  icon?: string;
}

export interface ButtonProps<ComponentType extends ElementType = 'button'> {
  classes?: ButtonClasses;
  variant?: ButtonVariant;
  iconLeft?: React.ElementType;
  iconLeftProps?: Omit<IconProps, 'icon' | 'className'>;
  iconRight?: React.ElementType;
  iconRightProps?: Omit<IconProps, 'icon' | 'className'>;
  onClick?: MouseEventHandler<ComponentType>;
}

export const Button = <ComponentType extends ElementType = 'button'>({
  className,
  classes,
  children,
  Component = 'button',
  disabled,
  variant = ButtonVariant.Primary,
  iconLeft,
  iconLeftProps,
  iconRight,
  iconRightProps,
  onClick,
  ...componentProps
}: WrapperComponentProps<ComponentType, ButtonProps<ComponentType>>) => {
  return (
    <Component
      className={clsx(s.Button, s[`Button_${variant}`], className, classes?.root, {
        [s.Button_disabled]: disabled,
        [s.Button_iconLeft]: !!iconLeft,
        [s.Button_iconRight]: !!iconRight
      })}
      onClick={onClick}
      {...(componentProps?.href && { target: '_blank', rel: 'noopener noreferrer' })}
      {...componentProps}
    >
      {iconLeft && <Icon className={classes?.icon} icon={iconLeft} size={IconSize.Small} {...iconLeftProps} />}
      <span>{children}</span>
      {iconRight && <Icon className={classes?.icon} icon={iconRight} size={IconSize.Small} {...iconRightProps} />}
    </Component>
  );
};
