import { createBreakpoint } from 'react-use';
import { cssVar } from 'utils/cssVar';

export enum Breakpoints {
  mobile = 'mobile',
  tablet = 'tablet',
  desktop = 'desktop'
}

export const breakpoints = {
  [Breakpoints.mobile]: parseFloat(cssVar('--bp-mobile-min-width')),
  [Breakpoints.tablet]: parseFloat(cssVar('--bp-tablet-min-width')),
  [Breakpoints.desktop]: parseFloat(cssVar('--bp-desktop-min-width'))
};

export const useBreakpoint = createBreakpoint(breakpoints);

export const useWindowSize = () => {
  const breakpoint = useBreakpoint();

  return {
    isMobile: breakpoint === Breakpoints.mobile,
    isTablet: breakpoint === Breakpoints.tablet,
    isDesktop: breakpoint === Breakpoints.desktop
  };
};
